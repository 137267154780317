<template>
<div>

 <h3> Dashboard Container</h3>
</div>
</template>

<script>
    export default {
        name: "DashboardContainer"
    }
</script>

<style scoped>

</style>
